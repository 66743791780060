import {useEffect, useState} from "react";

import MessagingWindow from "./components/messagingWindow";
import MessagingButton from "./components/messagingButton";

import { determineStorageType, initializeWebStorage, getItemInWebStorageByKey, getItemInPayloadByKey } from './helpers/webstorageUtils';
import { storeOrganizationId, storeDeploymentDeveloperName, storeSalesforceMessagingUrl } from './services/dataProvider';
import { APP_CONSTANTS, STORAGE_KEYS } from './helpers/constants';

import Draggable from "./ui-effects/draggable";

export default function BootstrapMessaging() {
    console.log(window.magentoData);
    let [shouldShowMessagingWindow, setShouldShowMessagingWindow] = useState(false);
    let [orgId, setOrgId] = useState(window.magentoData.salesforce_organisation_id);
    let [deploymentDevName, setDeploymentDevName] = useState(window.magentoData.salesforce_developer_name);
    let [messagingURL, setMessagingURL] = useState(window.magentoData.salesforce_url);

    let [showMessagingButtonSpinner, setShowMessagingButtonSpinner] = useState(false);
    let [shouldDisableMessagingButton, setShouldDisableMessagingButton] = useState(false);
    let [shouldShowMessagingButton, setShowMessagingButton] = useState(false);
    let [isExistingConversation, setIsExistingConversation] = useState(false);

    useEffect(() => {

        console.info(`run up here.`);

        const storage = determineStorageType();
        if (!storage) {
            console.error(`Cannot initialize the app. Web storage is required for the app to function.`);
            return;
        }

        const messaging_webstorage_key = Object.keys(storage).filter(item => item.startsWith(APP_CONSTANTS.WEB_STORAGE_KEY))[0];


        if (messaging_webstorage_key) {
            const webStoragePayload = storage.getItem(messaging_webstorage_key);
            const orgId = getItemInPayloadByKey(webStoragePayload, STORAGE_KEYS.ORGANIZATION_ID);
            const deploymentDevName = getItemInPayloadByKey(webStoragePayload, STORAGE_KEYS.DEPLOYMENT_DEVELOPER_NAME);
            const messagingUrl = getItemInPayloadByKey(webStoragePayload, STORAGE_KEYS.MESSAGING_URL);

            if (!isValidOrganizationId(orgId)) {
                console.warn(`Invalid organization id exists in the web storage: ${orgId}. Cleaning up the invalid object from the web storage.`);
                storage.removeItem(messaging_webstorage_key);
                // New conversation.
                setIsExistingConversation(false);
                return;
            }

            // Re-Initialize state variables from the values in the web storage. This also re-populates app's deployment parameters input form fields with the previously entered data, in case of a messaging session continuation (e.g. page reload).
            setOrgId(orgId);
            setDeploymentDevName(deploymentDevName);
            setMessagingURL(messagingUrl);

            // Initialize messaging client.
            initializeMessagingClient(orgId, deploymentDevName, messagingUrl);

            const messagingJwt = getItemInWebStorageByKey(STORAGE_KEYS.JWT);
            if (messagingJwt) {
                // Existing conversation.
                setIsExistingConversation(true);
                setShowMessagingButton(true);
                setShouldDisableMessagingButton(true);
                setShouldShowMessagingWindow(true);
            } else {
                // New conversation.
                setIsExistingConversation(false);
            }
        } else {
            // New conversation.
            setIsExistingConversation(false);
        }

        return () => {
            showMessagingWindow(false);
        };
    }, []);

    /**
     * Initialize the messaging client by
     * 1. internally initializing the Embedded Service deployment paramaters in-memory.
     * 2. initializing Salesforce Organization Id in the browser web storage.
     */
    function initializeMessagingClient(ord_id, deployment_dev_name, messaging_url) {
        // Initialize helpers.
        initializeWebStorage(ord_id || orgId);
        storeOrganizationId(ord_id || orgId);
        storeDeploymentDeveloperName(deployment_dev_name || deploymentDevName);
        storeSalesforceMessagingUrl(messaging_url || messagingURL);
    }

    /**
     * Validates whether the supplied string is a valid Salesforce Organization Id.
     * @returns {boolean}
     */
    function isValidOrganizationId(id) {
        return typeof id === "string" && (id.length === 18 || id.length === 15) && id.substring(0, 3) === APP_CONSTANTS.ORGANIZATION_ID_PREFIX;
    }

    /**
     * Determines whether to render the Messaging Window based on the supplied parameter.
     * @param {boolean} shouldShow - TRUE - render the Messaging WINDOW and FALSE - Do not render the Messaging Window & Messaging Button
     */
    function showMessagingWindow(shouldShow) {
        setShouldShowMessagingWindow(Boolean(shouldShow));
        if (!shouldShow) {
            // Enable Messaging Button again when Messaging Window is closed.
            setShouldDisableMessagingButton(false);
            // Remove the spinner on the Messaging Button.
            setShowMessagingButtonSpinner(false);
            // Hide Messaging Button to re-initialize the client with form submit.
            setShowMessagingButton(false);
        }
    }

    function handleDeploymentDetailsFormSubmit(evt) {
        if (evt) {
            // Initialize the Messaging Client.
            initializeMessagingClient();
            // New conversation.
            setIsExistingConversation(false);
            // Render the Messaging Button.
            setShowMessagingButton(true);
        }
    }

    /**
     * Handle a click action from the Messaging Button.
     * @param {object} evt - button click event
     */
    function handleMessagingButtonClick(evt) {
        if (evt) {
            console.log("Messaging Button clicked.");
            setShowMessagingButtonSpinner(true);
            showMessagingWindow(true);
        }
    }

    /**
     * Handles the app UI readiness i.e. Messaging Button updates based on whether the Messaging Window UI is rendered.
     * @param {boolean} isReady - TRUE - disable the Messaging Button & remove the spinner and FALSE - otherwise.
     */
    function appUiReady(isReady) {
        // Disable Messaging Button if the app is UI ready.
        setShouldDisableMessagingButton(isReady);
        // Remove the spinner on the Messaging Button if the app is UI ready.
        setShowMessagingButtonSpinner(!isReady);
    }

    return (
        <>
            <div className="deploymentDetailsForm">
            <input
                type="text"
                value={orgId || ""}>
            </input>

            <input
                type="text"
                value={deploymentDevName || ""}>
            </input>

            <input
                type="text"
                value={messagingURL || ""}>
            </input>

            <button
                className="deploymentDetailsFormSubmitButton"
                onClick={handleDeploymentDetailsFormSubmit}
            >
                Submit
            </button>
        </div>
    {shouldShowMessagingButton &&
    <MessagingButton
        clickHandler={handleMessagingButtonClick}
        disableButton={shouldDisableMessagingButton}
        showSpinner={showMessagingButtonSpinner} />}
    {shouldShowMessagingWindow &&
    <Draggable intitialPosition={{ x: 1000, y: 500 }}>
        <MessagingWindow
            isExistingConversation={isExistingConversation}
            showMessagingWindow={showMessagingWindow}
            deactivateMessagingButton={appUiReady} />
    </Draggable>
    }
</>
    );
}
