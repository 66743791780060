import BootstrapMessaging from "./BootstrapMessaging";

const ChatWrapper = () => {
    return (
        <div className="App">
            <header className="App-header">
                <BootstrapMessaging />
            </header>
        </div>
    );
}

export default ChatWrapper;
