const Elements = {

    getReactTestElement() {
        return document.getElementById('react-test')
    },

    getVariantsListListElement() {
        return document.getElementById('react-variant-list')
    },

    getOrderSimulatePriceElement() {
        return document.querySelector('.react-order-simulate-price')
    },

    getReactMessageBox() {
        return document.querySelector('.jsReactMessages')
    },

    getReactMessageChat() {
        return document.querySelector('.jsReactMessageChat')
    }



};

export default Elements;
