const LoadingMask = () => {
    const loaderUrl = window.staticContentUrl + '/images/loader-1.gif';

    console.info("loading...");

    return(
        <div className="loading-mask" data-role="loader" style={{position: "absolute"}} >
            <div className="loader">
                <img src={loaderUrl}  alt="Loading..." title="Loading..." style={{position: "absolute"}} />
            </div>
        </div>
    );
}

export default LoadingMask;
