import React from "react";
import ReactDOM from 'react-dom';

import { createRoot } from 'react-dom/client';
import Elements from "./utils/elements";

import Test from "./components/test";
import VariantsTable from "./components/variantsTable";
import {OrderSimulatePrice} from "./components";
import MessageBox from "./components/messages/MessageBox";
import ChatWrapper from "./components/chat/ChatWrapper";

window.addEventListener("DOMContentLoaded", (event) => {

    const reactTestElement = Elements.getReactTestElement();
    const variantsListElement = Elements.getVariantsListListElement();
    const reactMessageBox = Elements.getReactMessageBox();
    const reactMessageChatElement = Elements.getReactMessageChat();

    if (reactTestElement) {
        const testRoot = createRoot(reactTestElement);
        testRoot.render(<Test />);
    }

    if (variantsListElement) {
        const tabBar = document.querySelector('.tabbar_new.js-tabbar');

        tabBar.addEventListener('mouseover', function() {
            if (!variantsListElement.isRendered) {
                const variantListRoot = createRoot(variantsListElement);
                variantListRoot.render(<VariantsTable id={variantsListElement.dataset.id}
                                                      url={variantsListElement.dataset.url}
                                                      plant={variantsListElement.dataset.plant}/>);
                variantsListElement.isRendered = true;
            }
        });
    }

    /**
     * render message box for react here - this is rendered after the magento default message box (k.o.)
     *
     */
    if (reactMessageBox) {
        ReactDOM.render(<MessageBox />, reactMessageBox)
    }

    if (reactMessageChatElement) {
        const reactMessageChat = createRoot(reactMessageChatElement);
        reactMessageChat.render(<ChatWrapper />);
    }

    // Initial render
    const orderSimulatePriceElement = Elements.getOrderSimulatePriceElement();
    renderOrderSimulatePrice(orderSimulatePriceElement);

    // Update prices when opening mini cart
    if (document.querySelector('a.action.showcart')) {
      document.querySelector('a.action.showcart').addEventListener('click', () => {
        const miniCartOrderSimulatePriceElement = Elements.getOrderSimulatePriceElement();
        renderOrderSimulatePrice(miniCartOrderSimulatePriceElement);
      });
    }

    // Update prices when mana rerender or qty change
    document.addEventListener('livePriceUpdate', (event) => {
        const targetElement = event.detail.target?.querySelector('.react-order-simulate-price');

        if (targetElement) {
            targetElement.isRendered = false;
            renderOrderSimulatePrice(targetElement);
        }
    });
});

const renderOrderSimulatePrice = (element) => {
    if (element && !element.isRendered) {
        const orderSimulatePriceRoot = createRoot(element);
        orderSimulatePriceRoot.render(<OrderSimulatePrice url={element.dataset.url} />);
        element.isRendered = true;
    }
};

const renderOrderSimulatePriceForQuickOrder = (element) => {
    const orderSimulatePriceRoot = createRoot(element);
    orderSimulatePriceRoot.render(<OrderSimulatePrice />);
};

/**
 * use this event contentUpdatedForReact for detecting list changes in quick order. event is triggered
 * in 3th-party module
 *
 */
document.addEventListener('contentUpdatedForReact', function (event) {
    console.log("catch event");
    const orderSimulatePriceElement = Elements.getOrderSimulatePriceElement();
    renderOrderSimulatePriceForQuickOrder(orderSimulatePriceElement);
});

