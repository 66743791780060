import {useEffect, useState} from "react";

const MessageBox = () => {

    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    /**
     * @deprecated
     *
     * remove event handling after full react refactoring.
     * use context instead
     */
    useEffect(() => {
        document.addEventListener('jsReactAddSuccessMessage', function (event) {
            setSuccessMessage(event.detail.message);
        });

        document.addEventListener('jsReactAddErrorMessage', function (event) {
            setErrorMessage(event.detail.message);
        });
    }, []);

    return (successMessage || errorMessage) && (
        <div role="alert" className="messages">
            <div className={`infobox infobox--${successMessage ? 'success' : 'error'} infobox--emphasized message margin-top-15`}>
                <div className="infobox__wrapper">
                    <span className="infobox__text">
                        <div>{successMessage || errorMessage}</div>
                    </span>
                </div>
            </div>
        </div>
    );
}

export default MessageBox;
