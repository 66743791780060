import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/themes/base/all.css';
import { __ } from '../i18n';
import RepeatableTableRows from './content_loader/repeatableTableRows';
import InputSpinner from "./inputspinner";

function VariantsTable({ id, url, plant }) {
    const [products, setProducts] = useState([]);
    const [superAttributes, setSuperAttributes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isOnValidPage, setIsOnValidPage] = useState(false);
    const [customerIsLoggedIn, setCustomerIsLoggedIn] = useState(false);

    // Get data from API
    useEffect(() => {
        setLoading(true);
        $.ajax({
            url: `${url}rest/V1/variants/${id}`,
            method: 'GET',
            contentType: 'application/json',
            success: function(response) {
                const superAttributesObject = response[response.length - 1];
                const isOnValidPage = response[response.length - 2]?.isOnValidPage;
                const isLoggedIn = response[response.length - 2]?.isLoggedIn;
                const tempProducts = response.slice(0, -2);

                const superAttributes = Object.keys(superAttributesObject).map(key => ({
                    code: superAttributesObject[key].code,
                    label: superAttributesObject[key].label,
                    value: superAttributesObject[key].value
                }));

                setProducts(tempProducts);
                setSuperAttributes(superAttributes);
                setIsOnValidPage(isOnValidPage);
                setCustomerIsLoggedIn(isLoggedIn);
                setLoading(false);
            },
            error: function(xhr) {
                console.error("Request failed with status: " + xhr.status);
                setLoading(false);
            }
        });
    }, [id]);

    // Add modal to info icon
    useEffect(() => {
        const handleClick = (event) => {
            const modalHtml = `
                <div class="modal-backdrop"></div>
                <div class="modal modal--open">
                    <div class="modal__header">
                        <h3 class="modal__title">${__("Informationen zur Verfügbarkeitsabfrage")}</h3>
                        <a class="modal__close" data-dismiss="modal">
                            <span class="vacomicon icon-close"></span>
                        </a>
                    </div>
                    <div class="modal__content">
                        <p>${__("Mit unserer Live-Verfügbarkeitsabfrage können Sie in Echtzeit die Verfügbarkeit unserer Produkte einsehen.")}</p>
                    </div>
                </div>
            `;
            document.body.insertAdjacentHTML('beforeend', modalHtml);

            const closeModal = () => {
                document.querySelectorAll('.modal-backdrop, .modal').forEach(element => element.remove());
            };

            document.querySelectorAll('.vacomicon.icon-close').forEach(element => {
                element.addEventListener('click', closeModal);
            });
        };

        const elements = document.querySelectorAll('.js-modal.link.no-underline.vacomicon.icon-message-info');
        elements.forEach(element => element.addEventListener('click', handleClick));

        return () => {
            elements.forEach(element => element.removeEventListener('click', handleClick));
        };
    }, [products]);

    // availability modal config and render
    useEffect(() => {
        const handleClick = (event) => {
            if (!customerIsLoggedIn) {
                console.log('Customer must log in')
                window.location.replace(`${url}customer/account/login/`);
                return
            }

            const product = event.srcElement;
            const productSku = product.dataset.productSku
            const modalHtml = `
                <div class="modal-backdrop"></div>
                <div class="modal modal--open">
                    <div class="modal__header">
                        <h3 class="modal__title">Verfügbarkeitsabfrage</h3>
                        <a class="modal__close" data-dismiss="modal">
                            <span class="vacomicon icon-close ddd-modal-close"></span>
                        </a>
                    </div>
                    <div class="modal__content">
                        <div role="alert" class="messages hidden messages-check-availability">
                            <div class="infobox infobox--emphasized message margin-top-15" data-ui-id="message-error">
                                <div class="infobox__wrapper">
                                    <span class="infobox__text"></span>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-wrap w-100 modal-check-availability">
                            <div class="form-input-set w-25 margin-top-20">
                                <label class="margin-bottom-0">Menge</label>
                                <div class="spinner-wrapper requestDeliveryDate__spinner-dd w-25">
                                    <div id="input-spinner-container"></div>
                                </div>
                            </div>
                            <div id="datepicker-check-dd" class="margin-top-15 w-75">
                                <div>
                                    <div class="form-input-set">
                                        <div class="datepck input-group">
                                            <label for="datepicker" class="margin-bottom-0">Ihr Wunschlieferdatum</label>
                                            <div class="inner-addon right-addon">
                                                <span class="vacomicon icon-calendar"></span>
                                                <input class="form-input _has-datepicker" name="datepicker" id="variant-list-datepicker" type="text">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a class="btn btn--outline btn--large btn--block margin-top-20 button-check-availability">${__('Prüfen')}</a>
                    </div>
                </div>
            `;
            document.body.insertAdjacentHTML('beforeend', modalHtml);

            // datepicker config and render
            const firstAvailableDate = new Date();
            let addedDays = 0;
            const daysToAdd = 3;

            while (addedDays < daysToAdd) {
                firstAvailableDate.setDate(firstAvailableDate.getDate() + 1);
                const dayOfWeek = firstAvailableDate.getDay();
                if (dayOfWeek !== 0 && dayOfWeek !== 6) { // Check if the day is not Sunday(0) or Saturday(6)
                    addedDays++;
                }
            }

            // Set the placeholder date to the same firstAvailableDate
            const formattedPlaceholderDate = firstAvailableDate.toLocaleDateString('de-DE');
            // Normalize date to guarantee correct date calc
            function normalizeDate(date) {
                return new Date(date.getFullYear(), date.getMonth(), date.getDate());
            }

            $('#variant-list-datepicker').datepicker({
                dateFormat: 'dd.mm.yy',
                minDate: normalizeDate(firstAvailableDate),
                beforeShowDay: function(date) {
                    const normalizedDate = normalizeDate(date);
                    const isSelectable = normalizedDate >= normalizeDate(firstAvailableDate) && normalizedDate.getDay() !== 0 && normalizedDate.getDay() !== 6;
                    return [isSelectable, isSelectable ? 'ui-datepicker-selectable' : ''];
                },
                firstDay: 1,
                prevText: '<',
                nextText: '>'
            }).attr('placeholder', formattedPlaceholderDate).val(formattedPlaceholderDate);

            const closeModal = () => {
                document.querySelectorAll('.modal-backdrop, .modal').forEach(element => element.remove());
            };

            document.querySelectorAll('.vacomicon.icon-close').forEach(element => {
                element.addEventListener('click', closeModal);
            });

            // Render InputSpinner component into the container
            const inputSpinnerContainer = document.getElementById('input-spinner-container');
            if (inputSpinnerContainer) {
                ReactDOM.render(<InputSpinner />, inputSpinnerContainer);
            }

            const checkAvailability = () => {
                const openModal = $('.modal__content');
                let availabilityCheckQty = openModal.find('input[name="qty"]').val();
                let availabilityCheckDate = openModal.find('#variant-list-datepicker').val();
                let availabilityCheckPlant = plant
                let availabilityCheckSku = productSku

                if (availabilityCheckDate.includes('-')) {
                    let dateParts = availabilityCheckDate.split('-');
                    if (dateParts[2] && dateParts[2].length === 2) {
                        dateParts[2] = "20" + dateParts[2];
                    }
                    let newDate = new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
                    availabilityCheckDate = newDate.getFullYear() + '-' + (newDate.getMonth() + 1).toString().padStart(2, '0') + '-' + newDate.getDate().toString().padStart(2, '0');
                }

                if (availabilityCheckDate.includes('.')) {
                    let dateParts = availabilityCheckDate.split('.');
                    if (dateParts[2] && dateParts[2].length === 2) {
                        dateParts[2] = "20" + dateParts[2];
                    }
                    let newDate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
                    availabilityCheckDate = newDate.getFullYear() + '-' + (newDate.getMonth() + 1).toString().padStart(2, '0') + '-' + newDate.getDate().toString().padStart(2, '0');
                }

                const params = {
                    sku: availabilityCheckSku,
                    plant: availabilityCheckPlant,
                    reqdate: availabilityCheckDate,
                    qty: availabilityCheckQty
                };

                const availabilityApiRoute = `${url}rest/V1/availability/request/data/?sku=${params.sku}&plant=${params.plant}&reqdate=${params.reqdate}&qty=${params.qty}`;

                $.ajax({
                    url: availabilityApiRoute,
                    method: 'GET',
                    contentType: 'application/json',
                    success: function(data) {
                        switch (data[0].STATE) {
                            case null:
                            case "":
                                addSuccessMessage(availabilityCheckQty)
                                break;
                            case 'X':
                                addInfoMessage(data[0].AVAILABLE_QTY);
                                break;
                            case 'N':
                            default:
                                addErrorMessage();
                                break;
                        }
                    },
                    error: function() {
                        addErrorMessage()
                    }
                });
            }

            document.querySelector('.modal__content .button-check-availability').addEventListener('click', checkAvailability)

        };

        const elements = document.querySelectorAll('p.js-modal.link.no-underline');
        elements.forEach(element => element.addEventListener('click', handleClick));

        return () => {
            elements.forEach(element => element.removeEventListener('click', handleClick));
        };
    }, [products]);

    if (loading) {
        return <RepeatableTableRows rows={7} />;
    }

    const addMessage = (type, qty = null) => {
        const messageContainer = $('.messages-check-availability');
        let text;

        clearMessages();
        switch(type) {
            case 'info':
                text = __('Unfortunately only %1 is available. Please feel free to contact our support team.', qty)
                break;
            case 'success':
                text = __('The requested qty of %1 is available.', qty)
                break;
            case 'error':
            default:
                text = __('Unfortunately, we were unable to determine availability. Please feel free to contact our support team.');
                break;
        }

        messageContainer.removeClass('hidden');
        messageContainer.find('.infobox').addClass('infobox--' + type);
        messageContainer.find('.infobox').attr('data-ui-id','message-' + type);
        messageContainer.find('span').html(text)
    }

    const clearMessages = () => {
        const messageContainer = $('.messages-check-availability');
        const optionsArray = ['info', 'success', 'error'];

        optionsArray.forEach((elem) => {
            messageContainer.find('.infobox').removeClass('infobox--' + elem);
            messageContainer.find('.infobox').attr('data-ui-id','message-' + elem);
        })
        messageContainer.find('span').html('');
    }

    const addErrorMessage = () =>
    {
        addMessage('error');
    }

    const addInfoMessage = (availableQty) =>
    {
        addMessage('info', availableQty);
    }

    const addSuccessMessage =  (qty) =>
    {
        addMessage('success', qty);
    }

    return products.length > 0 && (
        <div className="table-container">
            <table className="table">
                <thead>
                <tr>
                    <th>{__("Product")}</th>
                    {superAttributes.map(attr => (
                        <th key={attr.code} className={`measurement-switcher none ${attr.label}`}>
                            {__(attr.label)}
                        </th>
                    ))}
                    <th>{__("Availability")}</th>
                </tr>
                </thead>
                {products.map(product => (
                    <tbody key={product.id}>
                    <tr>
                        <td className="product-link"><a className="link c-purple" href={product.url}>{product.name}</a></td>
                        {superAttributes.map(attr => (
                            <td key={attr.code} className={`measurement-switcher none ${attr.label}`}>
                                {product.superAttributes[attr.code]}
                            </td>
                        ))}
                        <td>
                            <div className="availability__wrapper">
                                <div className="availability-light">
                                    <div className={`availability availability--${product.isVacomNow ? 'green' : product.availability} margin-right-10`}></div>
                                    <div className="availability__text">
                                        <span>{__(`availability_${product.isVacomNow ? 'green' : product.availability}`)}</span><br/>
                                    </div>
                                </div>
                                {product.isVacomNow && isOnValidPage && (
                                    <div className="requestDeliveryDate">
                                        <label className="requestDeliveryDate__label" style={{margin: 0}}>
                                            <p className="js-modal link no-underline"
                                               data-modal="#requestDeliveryDate-modal"
                                               data-title={__("availability check")}
                                               data-product-sku={product.sku}
                                               style={{margin: 0}}
                                            >
                                                {__("Check availability")}
                                            </p>
                                            <span className="js-modal link no-underline vacomicon icon-message-info"
                                                  data-modal="#requestDeliveryDateInfo-modal"
                                                  data-title={__("Check availability infos")}></span>
                                        </label>
                                    </div>
                                )}
                            </div>
                        </td>
                    </tr>
                    </tbody>
                ))}
            </table>
        </div>
    );
}

export default VariantsTable;
