import localStorage from "../utils/localStorage";

function Test() {
    console.log('I Can Render React.');
    if (localStorage) {
        console.log('I Can Read Local Storage.');
        console.log('Customer ID:' + localStorage.getCustomer());
    } else {
        console.log('I Can NOT Read Local Storage.');
    }
}

export default Test;