import React, { useState, useEffect } from 'react';

function InputSpinner() {
    const [value, setValue] = useState(1);
    const [isDecreaseDisabled, setIsDecreaseDisabled] = useState(true);
    const [isIncreaseDisabled, setIsIncreaseDisabled] = useState(false);
    const minValue = 1;
    const maxValue = 99999;

    useEffect(() => {
        setIsDecreaseDisabled(value <= minValue);
        setIsIncreaseDisabled(value >= maxValue);
    }, [value]);

    const handleDecrease = () => {
        setValue(prevValue => Math.max(prevValue - 1, minValue));
    };

    const handleIncrease = () => {
        setValue(prevValue => Math.min(prevValue + 1, maxValue));
    };

    const handleChange = (event) => {
        const newValue = parseInt(event.target.value, 10);
        if (!isNaN(newValue)) {
            setValue(Math.min(Math.max(newValue, minValue), maxValue));
        }
    };

    return (
        <div className="inp-spinner js-InputSpinner" data-ve="1">
            <span
                type="button"
                className={`inp-spinner__button inp-spinner__decrease ${isDecreaseDisabled ? 'inp-spinner__button--disabled' : ''}`}
                onClick={handleDecrease}
            >
                -
            </span>
            <input
                type="number"
                name="qty"
                className="inp-spinner__input"
                data-steps="1"
                data-minvalue={minValue}
                data-maxvalue={maxValue}
                value={value}
                pattern="[0-9]+"
                onChange={handleChange}
            />
            <span
                type="button"
                className={`inp-spinner__button inp-spinner__increase ${isIncreaseDisabled ? 'inp-spinner__button--disabled' : ''}`}
                onClick={handleIncrease}
            >
                +
            </span>
        </div>
    );
}

export default InputSpinner;