/**
 * example for uuid: e351795a-6ee6-0f4d-b225-2ec615d13213
 */
import { useEffect, useState } from 'react';
import {__} from "../../i18n";
import LoadingMask from "../content_loader/LoadingMask";

export function OrderSimulatePrice() {
    const [loading, setLoading] = useState(true);
    const [newPriceData, setNewPriceData] = useState([]);
    const [itemData, setItemData] = useState([]);
    const [apiData, setApiData] = useState([]);

    useEffect( () => {
        if (apiData.length > 0) {
            fetchPrices();
        }

    }, [apiData]);

    const fetchPrices = async () => {
        const apiUrl = window.BASE_URL + `rest/V1/request/price`;

        const header = {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest"
        }

        const transformedData = apiData.reduce((acc, item, index) => {
            acc[index + 1] = item;
            return acc;
        }, {});

        const bodyData = { data: transformedData };

        const response = await fetch(apiUrl, {
            method: "POST",
            headers:header,
            body: JSON.stringify(bodyData),
        }).then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        }).then((response) => {

            let data = null;

            if (response && response[1] !== undefined) {
                data = response[1];
            } else {
                throw new Error(__('Live Price Data could not be fetched'));
            }

            const priceData = Object.keys(data).map(key => {
                const item = data[key];
                let newPrice = item.price;
                let strikePrice = null;

                if (item.strike_price) {
                    const customerPrice = parseFloat(item.strike_price.customer.replace(',', '.').replace(' €', ''));
                    const listPrice = parseFloat(item.strike_price.list.replace(',', '.').replace(' €', ''));

                    if (customerPrice < listPrice) {
                        newPrice = item.strike_price.customer;
                        strikePrice = item.strike_price.list;
                    } else {
                        newPrice = item.strike_price.list;
                        strikePrice = item.strike_price.customer;
                    }
                }

                return {
                    index: parseInt(key, 10),
                    sku: item.sku,
                    qty: item.qty,
                    newPrice: newPrice,
                    strikePrice: strikePrice
                };
            });

            setNewPriceData(priceData);

        }).catch((error) => {
            console.error("Error:", error);

            const fallbackPriceData = itemData.map(item => ({
                index: item.index,
                sku: item.sku,
                qty: item.qty,
                newPrice: item.oldPrice,
                strikePrice: null
            }));

            setNewPriceData(fallbackPriceData);
            displayReactError(__('Live Price Data could not be fetched'));
        }).finally(() => {
            setLoading(false);
            clearStrikePrices();
        });
    }

    useEffect(() => {
        const elementsToCheck = Array.from(document.querySelectorAll('.order-simulate-item'))
            .filter(element => element.dataset.priceUpdated !== 'true');

        const newItemData = [];
        const newApiData = [];

        elementsToCheck.forEach((element, index) => {
            let priceElement = element.querySelector('span.price');
            let oldPrice = priceElement ? priceElement.innerHTML : '';
            let sku = element.dataset.sku;
            let qty = element.dataset.qty ?? 1;

            if (sku === 'FAKEPRODUCT') {
                sku = element.closest('.amqorder-item').querySelector('.amqorder-sku .amqorder-text').textContent.trim();
            }

            if (!element.dataset.qty) {
                const itemElem = element.closest('li[class^="amqorder-item"]')
                qty = itemElem ? Math.max(itemElem.querySelector('.amqorder-qty-block').getAttribute('qty'), itemElem.querySelector('.amqorder-qty-block input').getAttribute('value')) : 1;
            }

            const specialPriceElement = element.querySelector('span.special-price .price-wrapper');
            if (specialPriceElement) {
                priceElement = specialPriceElement;
                oldPrice = specialPriceElement.innerHTML;
            }

            if (!priceElement) {
                priceElement = document.createElement('span');
                priceElement.className = 'price';
                element.querySelector('.price-box')?.appendChild(priceElement);
            }

            newApiData.push({
                index: (index + 1) * 10,
                sku: sku,
                qty: qty,
            });

            newItemData.push({
                index: (index + 1) * 10,
                sku: sku,
                qty: qty,
                priceElement: priceElement,
                oldPrice: oldPrice
            });
        });

        setItemData(newItemData);
        setApiData(newApiData);

    }, []);

    useEffect(() => {
        if (!loading) {
            newPriceData.forEach((item) => {
                const priceElement = itemData.find(data => data.index === item.index)?.priceElement;
                const parentElement = priceElement?.closest('.order-simulate-item');
                if (priceElement && parentElement) {

                    if (item.strikePrice) {
                        const strikePriceHtml = `
                        <div class="price__top">
                            <sup>
                                <span class="price__crossed-out">
                                    <span class="price-wrapper" data-price-type="oldPrice">${item.strikePrice}</span>
                                </span>
                            </sup>
                        </div>
                    `;
                        priceElement.innerHTML = `<span class="new-price">${item.newPrice}</span>${strikePriceHtml}`;
                    } else {
                        priceElement.innerHTML = item.newPrice;
                    }
                    parentElement.dataset.priceUpdated = 'true';
                }
            });
        }
    }, [loading]);

    /**
     * @deprecated
     *
     * remove event handling after full react refactoring.
     * use context instead
     */
    const displayReactError = (message) => {
        let customEvent = new CustomEvent('jsReactAddErrorMessage', {
            bubbles: true,
            detail: {
                message: message
            }
        });
        document.dispatchEvent(customEvent);
    }

    /**
     * @deprecated
     */
    const clearStrikePrices = () => {
        const priceDivs = document.querySelectorAll('.price__top .price__crossed-out')
        priceDivs.forEach((priceDiv) => {
            priceDiv.closest('.price__top').remove();
        })
    }

    return (
        loading && (<LoadingMask />)
    );
}
export default OrderSimulatePrice;
